footer {
  padding: 0 10rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  background: var(--color-primary);
  padding: 5rem 0;
  text-align: center;
  font-size: 0.9rem;
  padding-top: 7rem;
}

footer a {
  color: var(--bg-color);
}

.about-header-divider-variant {

}

.vl-left {
  border-right: 2px solid var(--bg-color);
  height: 10rem;
}

.vl-right {
  border-left: 2px solid var(--bg-color);
  height: 10rem;
}

.footer-logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer-socials a {
  background: var(--bg-color);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}



@media  (hover: hover) and (pointer: fine) {
  .footer-socials a:hover {
    background: transparent;
    color: var(--bg-color);
    border-color: var(--bg-color);
  }
}

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
  }
  .vl-left, .vl-right {
    height: 22rem;
  }
}

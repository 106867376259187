

.modal {
  position: fixed;
  visibility: visible;
  opacity: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .7);
  transition: all .4s;
}

.modal__content {
  border-radius: 2rem;
  position: relative;
  width: 1fr;
  max-width: 90%;
  background: var(--bg-color);
  padding: 1em 2em;
}

.modal-button, .modal-header {
  display: flex;
  padding: 2rem;
  justify-content: center;
  gap: 2rem;
}

.modal-text-description{
  margin-top: 1rem;
  margin-bottom: 1rem;
}


/* ======================== media queries ========================*/
@media screen and (max-width: 600px) {
  .modal-button-indv {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }

  .modal-button {
    padding: 2rem 0 2rem 0;
  }
}

.container.contact-container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact-div {
  background: var(--bg-color-four);
  padding-bottom: 0;
}

.about-header-divider-variant {
  display: grid;
  grid-template-columns: 1fr;
  background: var(--bg-color);
  color: var(--bg-color);
  margin-top: 8rem;
  margin-bottom: 0;
}

.contact-options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact-option {
  background: var(--bg-color);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact-icon {
  font-size: 1.5rem;
  margin-bottom:0.5rem;
}

.contact-option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contact-option h5 {
  font-size: 1rem;
}



/* ======================= from ========================= */

::placeholder {
  color: var(--color-white);
  opacity: 0.75;
}
form  {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
}

input, textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: var(--bg-color);
  border: 2px solid var(--color-priamry-variant);
  resize: none;
  color: var(--color-white);
}


/*  ========== MEDIA QUERIES ================*/

@media (hover: hover) and (pointer: fine) {
  .contact-option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
  }
}

/* MEDIUM DEVICES */

@media screen and (max-width: 1024px) {
  .container.contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

}

/* SMALL DEVICES */

@media screen and (max-width: 600px) {
  .container.contact-container {
    width: var(--container-width-sm);
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  /* colors */
  /* --bg-color: #1f1f38;
  --bg-color-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-priamry-variant: rgba(77, 181, 255, 0.4); */
  --bg-color: #161616;
  --bg-color-variant: #575757;
  --bg-color-two: #272727;
  --bg-color-three: #373737;
  --bg-color-four: #575757;
  --color-primary: rgba(232, 23, 93, 0.8);
  --color-priamry-variant: #cc527a;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-success: #12a339;
  --color-black: black;

  /* container width */

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  /* trainsition */

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--bg-color);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(assets/background.png); */
}

/* general styles */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 4rem;
}

h5 {
  font-size: 2rem;
}

section {
  margin-top: 0;
  padding-bottom: 10rem;
}

.about-header-divider {
  display: grid;
  grid-template-columns: 1fr;
  background: var(--color-primary);
  color: var(--color-primary);
  margin-bottom: 8rem;
}

svg {
  /* margin-top: 1rem; */
  height: 1.5em;
  width: 1.5em;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a, button {
  font-family: 'Poppins', sans-serif;
  color: var(--color-primary);
  transition: var(--transition);
}


.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-white);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}


.btn-primary {
  background: var(--color-primary);
  color: var(--color-white);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}


.hidden {
  display: none;
}


/*  ========== MEDIA QUERIES ================*/

/* MEDIUM DEVICES */

@media (hover: hover) and (pointer: fine) {
  a:hover {
    color: var(--color-white);
  }

  .btn:hover {
    background: var(--color-white);
    color: var(--bg-color);
    border-color: transparent;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* SMALL DEVICES */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-top: 2rem;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  .container, nav {
    zoom: 70%;
  }
}

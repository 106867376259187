header {
  height:100vh;
  padding-top: 7rem;
  overflow: hidden;
  background: var(--color-black);
  background-image: url(../../assets/background.png);
  posistion: absolute;
}

.header-name {
  color: var(--color-primary);
}

.header-container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* buttons */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}


/* socials */

.header-socials {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom : 0;
  font-size: 2rem;
}

.socials {
  margin-top: 1rem;
}

.header-socials::after {
  margin-top: 1rem;
  content: "";
  width: 1px;
  height: 8rem;
  background: var(--color-primary);
}





/*  ========== MEDIA QUERIES ================*/


/* SMALL DEVICES */

@media screen and (max-width: 600px) {
  .header-socials {
    display: none;
  }

  header {
    height: 100vh;
    padding-top: 50px;
  }

  .me-img {
    height: 25%;
  }

  h1 {
    font-size: 2.7rem;
  }
  h5 {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1920px and max-height: 1080px) {

  header {
    height: 100vh;

  }
}

.project-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.5rem;
}

.project-section {
  background: var(--bg-color-three);
}

.project-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--bg-color-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.project-item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  aspect-ratio: 1/1;

}

.project-item h3 {
  margin: 1.2rem 0 2rem;
  text-align: center;
}

.project-item-button {
  display: flex;
  justify-content: center;
  transition: var(--transition);
}

/* =========================== media queries =========================*/

@media (hover: hover) and (pointer: fine) {
  .project-item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
  }
}

@media screen and (max-width: 1024px) {
  .project-container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* SMALL DEVICES */

@media screen and (max-width: 600px) {
  .project-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.about-container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about-header-divider {
  display: grid;
  grid-template-columns: 1fr;
  background: var(--color-primary);
  color: var(--color-primary);
  margin-bottom: 8rem;
}

section {
  margin: 0;
}

.about-me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
}

.about-me-image {

  border-radius: 2rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}


.about-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about-card {
  background: var(--bg-color-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}


.about-icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about-card h5 {
  font-size: 0.95rem;
}

.about-card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about-content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

.p-color {
  color: var(--color-primary);
}


/*  ========== MEDIA QUERIES ================*/


/* SMALL DEVICES */

@media (hover: hover) and (pointer: fine) {
  .about-me-image:hover {
    transform: rotate(0deg);
  }

  .about-card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
  }
}

@media screen and (max-width: 600px) {
  .about-me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .education-card {
    grid-area: education;
  }

  .about-cards {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: '. .'
                         'education education';
    gap: 1rem;
  }

  .about-content {
    text-align: center;
  }

  .about-content p {
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 1024px) {
  .about-container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about-me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about-content p {
    margin:1rem 0 1.5rem;
  }

  .about-content {
    text-align: center;
  }
}

@media screen and (max-width: 1920px and max-height: 1080px) {

  header {
    height: 100vh;

  }
}
